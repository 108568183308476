import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { initializeApp } from 'firebase/app'
import { getFirestore } from "firebase/firestore"
import PrimeVue from 'primevue/config'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import ProgressSpinner from 'primevue/progressspinner';

import 'primevue/resources/themes/lara-light-green/theme.css'
import 'primeflex/primeflex.scss'
import 'primeflex/themes/primeone-light.css'
import 'primeicons/primeicons.css'

// import '@/assets/styles.scss';

const firebaseConfig = {
    apiKey: "AIzaSyBbKxoUuj8PTuH3aPmFs0tn6vSRpOzBwSI",
    authDomain: "cathxsansiri-genai.firebaseapp.com",
    projectId: "cathxsansiri-genai",
    storageBucket: "cathxsansiri-genai.appspot.com",
    messagingSenderId: "1056406449165",
    appId: "1:1056406449165:web:382a65aa19042966497944"
};
  
const firebaseApp = initializeApp(firebaseConfig);
const firestoreDb = getFirestore(firebaseApp)

import App from './App.vue'
import router from './router'

// liff.init({
//     liffId: import.meta.env["VITE_LINE_LIFF_ID"],
// });

const app = createApp(App)
const appContext = {
    isDev: import.meta.env.DEV
}

app.use(createPinia())
app.use(router)
app.use(PrimeVue, { ripple: true })
// app.provide("liff", liff)
app.provide("app-context", appContext)
app.provide("firebase", firebaseApp)
app.provide("firestore", firestoreDb)
app.component('Dropdown', Dropdown)
app.component('InputText', InputText)
app.component('Button', Button)
app.component('ProgressSpinner', ProgressSpinner)

app.mount('#app')
